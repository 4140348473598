import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useGetMeasures from "../../../hooks/useGetMeasures";
import MeasureListItem from "./MeasureListItem";

const MeasureList = ({ filter, onStoreClick }) => {
	// const measures = useSelector((state) => state.measures.data);

	const { projectId } = useParams(); //useSelector((state) => state.info?.project?.id);
	const measureMode = useSelector((state) => state.measures.mode);

	const { measures } = useGetMeasures({ projectId, dimension: measureMode });

	const [selected, setSelected] = React.useState(null);

	return (
		<div className="flex flex-col pb-4 gap-[10px] overflow-auto">
			{measures
				.filter((m) => (m.isHiddenInSidebar ? false : true))
				.filter(filter)
				.map((measure) => (
					<MeasureListItem
						onStoreClick={onStoreClick}
						key={measure.id}
						selected={selected?.id === measure.id}
						measure={measure}
						onClick={() => {
							if (selected === measure) setSelected(null);
							else setSelected(measure);
						}}
					/>
				))}
		</div>
	);
};

export default MeasureList;
